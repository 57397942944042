import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ApiService from "@/services/ApiService";
import IMedia from "@/interfaces/IMedia";

@Component({})
export class HandlesUploads extends Vue {
  protected apiService: ApiService = new ApiService();

  @Prop({ required: false, default: 16 })
  protected maxFilenameLength!: number;

  @Prop({ required: false, default: null })
  protected value!: any;

  @Prop({ required: false, default: null })
  protected resource!: string | null;

  @Prop({ required: false, default: "" })
  protected accept!: string;

  @Prop({ required: false, default: false })
  protected hideUploadButton!: boolean;

  @Prop({ required: false, default: null })
  protected media!: IMedia | null;

  @Watch("value", { immediate: true, deep: true })
  protected onValueChanged(file: File | null): void {
    if (file) {
      this.file = file;
      this.validationErrors = null;
    }
  }

  protected clear(): void {
    this.onUnselectFile();
    this.validationErrors = null;
  }

  created() {
    this.clear();
  }

  protected file: File | null = null;
  protected isNewFile = false;
  protected isUploading = false;

  protected validationErrors: any = null;

  protected onFileSelect(file: File): void {
    this.validationErrors = null;
    this.isNewFile = true;
    this.$emit("input", file);
  }

  protected onUnselectFile(): void {
    this.file = null;
    this.isNewFile = false;
  }

  protected onUploadClicked(): void {
    this.$emit("before-upload", this.file);
    this.upload();
  }

  protected upload(): void {
    this.isUploading = true;
    if (this.resource) {
      this.apiService
        .upload({
          resource: this.resource,
          data: {
            file: this.file,
          },
        })
        .then((response: any) => {
          this.$emit("uploaded", response.data);
          this.onUnselectFile();
        })
        .catch((error: any) => {
          if (error.response.status === 422) {
            if (error.response?.data?.errors) {
              this.validationErrors = error.response.data.errors;
            }
          }
        })
        .finally(() => {
          this.isUploading = false;
        });
    }
  }
}

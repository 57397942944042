
import { Component, Prop } from "vue-property-decorator";
import Str from "../../helper/Str";
import { mixins } from "vue-class-component";
import { HandlesUploads } from "@/components/media/mixin/HandlesUploads";
import { MIME_TYPES } from "@/config/Consts";

@Component({
  computed: {
    Str() {
      return Str;
    },
  },
  components: {},
  mixins: [HandlesUploads],
})
export default class ImageViewAndUpload extends mixins(HandlesUploads) {
  protected isHovered = false;

  @Prop({
    required: false,
    default: () => {
      return MIME_TYPES.filter((mime: any) => {
        return (
          mime.mime.indexOf("image/") > -1 && mime.mime.indexOf("/webp") < 0
        );
      })
        .map((mime: any) => {
          return mime.mime;
        })
        .join(",");
    },
  })
  protected accept!: string;

  protected get fileUrl(): string {
    if (this.file) {
      return window.URL.createObjectURL(new Blob([this.file]));
    }

    if (this.media?.preview_url) {
      return this.media.preview_url;
    }

    if (this.media?.original_url) {
      return this.media.original_url;
    }
    return "https://picsum.photos/400/300";
  }
}
